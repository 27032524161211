/* global Cookies */

$(document).ready(function() {
  let COOKIE_DOMAIN = $('#cookie-domain').val();

  let backToPrev = function() {
    $('#body-container').removeClass('is-frozen');
    $('.reveal-overlay').hide();
    if (document.referrer == '' || document.referrer == window.location.href) {
      if (window.location.href.replace(/(^\w+:|^)\/\//, '').includes(window.location.hostname)) {
        window.location.href = `https://${window.location.hostname}`;
      } else {
        window.location.href = window.location.hostname;
      }
    } else if (document.referrer.replace(/(^\w+:|^)\/\//, '').includes(window.location.hostname)) {
      window.location.href = document.referrer;
    } else {
      window.location.href = `https://${window.location.hostname}`;
    }
  };

  let processLink = function(selectionType) {
    if (selectionType == 'personal') {
      if (COOKIE_DOMAIN != 'None' && COOKIE_DOMAIN.length > 5) {
        document.cookie = `fidelity-ce-web=ce.private-investor;path=/;secure;domain=${COOKIE_DOMAIN}`;
      } else {
        $('#body-container').removeClass('is-frozen');
        document.cookie = 'fidelity-ce-web=ce.private-investor;path=/;secure;';
      }
      backToPrev();
    } else if (selectionType == 'professional') {
      if (COOKIE_DOMAIN != 'None' && COOKIE_DOMAIN.length > 5) {
        document.cookie = `fidelity-ce-web=ce.professional-investor;path=/;secure;domain=${COOKIE_DOMAIN}`;
      } else {
        document.cookie = 'fidelity-ce-web=ce.professional-investor;path=/;secure;';
      }
      $('#body-container').removeClass('is-frozen');
      $('.reveal-overlay').hide();
    }
  };

  let processPopup = function() {
    $('.de-revealclose').on('click', function() {
      $('.reveal-overlay').hide();
    });
    if (Cookies) {
      let ceCookie = Cookies.get('fidelity-ce-web');
      /* When Cookie not present*/
      if (!ceCookie) {
        setTimeout(showProfessionalOverlay, 1);
      } else if (ceCookie.indexOf('ce.professional-investor') != -1) {
        /* When Cookie contains ce.professional-investor */
        /* No Popup */
        $('#body-container').removeClass('is-frozen');
      } else if (ceCookie.indexOf('ce.private-investor') != -1) {
        setTimeout(showPersonalOverlay, 1);
      }
    }
  };

  const showProfessionalOverlay = function() {
    $('.reveal-overlay').show();
    $('#personal').hide();
  };

  const showPersonalOverlay = function() {
    $('.reveal-overlay').show();
    $('#professional').hide();
    $('#personal').show();
  };

  $('a[data-id=\'welcome_professional\']').on('click', function() {
    processLink('professional');
  });

  $('a[id=\'professional_link\']').on('click', function() {
    processLink('professional');
  });

  $('a[data-id=\'welcome_personal\']').on('click', function() {
    processLink('personal');
  });

  $('a[data-id=\'welcome_conclude\']').on('click', function() {
    backToPrev();
  });

  let checkifCookiePopUpPresent = function() {
    if ($('#body-container').hasClass('cookiePopUpPresent')) {
      $('#body-container').addClass('is-frozen');
      processPopup();
    }
  };

  checkifCookiePopUpPresent();
});
